<script setup>
import { computed } from 'vue'
import { useAuthentication } from '@/composables/authentication'
import { usePasswords } from '@/composables/passwords'
import PasswordField from '@/components/ui/fields/PasswordField.vue'
import Btn from '@/components/ui/Btn.vue'

const { confirmLogin } = useAuthentication()
const {
  password,
  confirmPassword,
  passwordsMatch,
  showPasswordMatch,
  passwordStrength,
  updatePasswordStrength,
  setPasswordValid
} = usePasswords()

const props = defineProps({
  username: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['password-set'])

const canSetPassword = computed(() => {
  if (!password.value) {
    return false
  }
  if (!passwordStrength.value.valid) {
    return false
  }
  if (!passwordsMatch.value) {
    return false
  }
  // Password can therefore be reset
  return true
})

async function confirmSetPassword() {
  if (canSetPassword.value) {
    const result = await confirmLogin(props.username, password.value)

    if (result.isSignedIn && result.nextStep.signInStep === 'DONE') {
      emit('password-set')
    }
  }
}
</script>

<template>
  <div>
    <h2>Please set a new password before continuing</h2>
    <form>
      <input name="username" type="text" :value="username" class="hidden" aria-hidden="true" />
      <label for="password" class="mx-1 !mt-8 !mb-0">New Password</label>
      <PasswordField
        context="forgot"
        intent="new"
        v-model="password"
        @submit="confirmSetPassword"
        @score="updatePasswordStrength"
        @valid="setPasswordValid"
        placeholder="Enter your password"
        class="!h-field rounded !m-1 !text-lg !text-left !font-medium w-full"
      />
      <warning v-if="passwordStrength.weak">
        <div v-if="passwordStrength.warning">{{ passwordStrength.warning }}</div>
        <div v-if="passwordStrength.warning && passwordStrength.suggestions">
          <br />
        </div>
        <div v-if="passwordStrength.suggestions">
          <strong>Hints:</strong>
          <div v-for="suggestion in passwordStrength.suggestions" class="danger" :key="suggestion">
            {{ suggestion }}
          </div>
        </div>
      </warning>
      <label for="confirm" class="mx-1 !mt-8 !mb-0">Confirm Password</label>
      <PasswordField
        context="forgot"
        intent="confirm"
        v-model="confirmPassword"
        @submit="confirmSetPassword"
        placeholder="Enter your password"
        class="!h-field rounded !m-1 !text-lg !text-left !font-medium w-full"
      />
      <div v-show="showPasswordMatch">
        <danger>Passwords do not match</danger>
      </div>
      <Btn
        size="lg"
        severity="primary-yellow-login"
        :action="confirmSetPassword"
        :disabled="!canSetPassword"
      >
        Confirm Password
      </Btn>
    </form>
  </div>
</template>

<style scoped lang="scss"></style>
